import { useConfirmationDialog } from '@app/components/ConfirmDialog';
import { Stack, Typography } from '@mui/material';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import React, { forwardRef, useRef } from 'react';
import CancelIntegrationButton from './CancelIntegrationButton';
import DefaultAuthModal from './DefaultAuthModal';
import addNotification from '../../../utils/addNotification';

const DefaultLogoutButton = forwardRef(
  (
    {
      query,
      setGoogleMeetIntegrationIsActive,
      setGoogleMeetImportIsActive,
      disabled,
      modalTitle,
      modalDescription,
      cancelIntegration,
      cancelIntegrationText
    },
    ref
  ) => {
    const { isLoading, refetch } = query;
    const logoutModalRef = useRef(null);
    const getConfirmation = useConfirmationDialog();
    const onSignInWithGoogleMeetHandler = () => {
      logoutModalRef.current.openModal();
    };
    const onConfirmLogoutModal = async () => {
      try {
        await cancelIntegration();
        await getConfirmation({
          title: (
            <Stack alignItems="center" direction="row" gap={2}>
              <TaskAltIcon color="success" />
              Sucesso
            </Stack>
          ),
          message: (
            <Typography>
              A integração com o <b>{cancelIntegrationText}</b> foi desfeita com sucesso.
            </Typography>
          ),
          cancelButtonProps: { sx: { display: 'none' } },
          confirmButtonProps: { children: 'Fechar' }
        });
        setGoogleMeetImportIsActive(false);
        setGoogleMeetIntegrationIsActive(prevState => !prevState);
        logoutModalRef.current.closeModal();
        await refetch();
      } catch {
        addNotification({
          message: 'Erro ao tentar desfazer a integração.',
          type: 'danger'
        });
      }
    };
    return (
      <>
        <CancelIntegrationButton disabled={disabled || isLoading} onClick={onSignInWithGoogleMeetHandler} />
        <DefaultAuthModal
          ref={logoutModalRef}
          buttonConfirmTitle="Desfazer integração"
          title={modalTitle}
          description={modalDescription}
          onConfirm={onConfirmLogoutModal}
        />
      </>
    );
  }
);

export default DefaultLogoutButton;
