import { IconButton, Stack } from '@mui/material';
import { ArrowBackIosNewRounded as ArrowBackIcon, ArrowForwardIosRounded as ArrowForwardIcon } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { getSlidesResponsive } from '../utils';
import SpeakerItem from './SpeakerItem';

const CarouselArrow = ({ icon, onClick, sx }) => (
  <Stack sx={{ ...sx }}>
    <IconButton onClick={onClick} sx={{ width: 40 }}>
      {icon}
    </IconButton>
  </Stack>
);

const Speakers = ({ isLoading, speakers }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const carouselRef = useRef();
  const totalItems = speakers.length;
  const maxIndex = Math.ceil(totalItems / itemsPerPage) - 1;
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const responsive = getSlidesResponsive(2, 4, 6);
      const breakpoints = [
        { minWidth: 1080, items: responsive[1080].items },
        { minWidth: 1024, items: responsive[1024].items },
        { minWidth: 768, items: responsive[768].items }
      ];
      const { items } = breakpoints.find(breakpoint => width >= breakpoint.minWidth) || { items: 1 };
      setItemsPerPage(items);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isLastSlide = activeIndex === maxIndex;
  const nextSlideHandler = evt => {
    if (activeIndex < maxIndex) {
      setActiveIndex(activeIndex + 1);
      return carouselRef?.current?.slideNext(evt);
    }
  };
  const prevSlideHandler = evt => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
      return carouselRef?.current?.slidePrev(evt);
    }
  };
  const speakersItem = useMemo(() => speakers.map(currentSpeaker => <SpeakerItem speaker={currentSpeaker} key={currentSpeaker.uuid} />), [speakers]);
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ mt: 5, width: '100%' }}>
      {isLoading || !speakers.length ? (
        <></>
      ) : (
        <>
          <CarouselArrow icon={<ArrowBackIcon sx={{ color: activeIndex > 0 ? '#7E3AE7' : 'neutral.body' }} />} onClick={prevSlideHandler} />
          <Stack alignItems="center" direction="row" sx={{ width: '80%' }}>
            <AliceCarousel
              ref={carouselRef}
              activeIndex={activeIndex}
              animationDuration={800}
              animationType="fadeout"
              disableButtonsControls
              disableDotsControls
              items={speakersItem}
              responsive={getSlidesResponsive(2, 4, 6)}
            />
          </Stack>
          <CarouselArrow icon={<ArrowForwardIcon sx={{ color: isLastSlide ? 'neutral.body' : '#7E3AE7' }} />} onClick={nextSlideHandler} />
        </>
      )}
    </Stack>
  );
};

export default Speakers;
