import { Avatar, Button, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { InfoOutlined as InfoOutlinedIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import React from 'react';
import LogModal from './LogModal';

const MobileRow = ({ data, open, setOpen, showMore, setShowMore, showMoreButtonLabel, recordIsArray, eventType, createdAt, icon }) => (
  <Stack direction="row" sx={{ pl: 3 }}>
    {icon()}
    <Stack sx={{ width: '100%' }}>
      <Stack alignItems="center" direction="row" gap={1} sx={{ ml: 2 }}>
        <Stack>
          <Typography sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 400 }}>{eventType}</Typography>
          <Typography sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 600, mt: 2 }}>{data?.identifier}</Typography>
          {recordIsArray && showMore && (
            <List sx={{ listStyleType: 'disc', pl: 4 }}>
              {data?.record.map(record => {
                const jsonObject = {};
                if (Array.isArray(record.Value)) {
                  record.Value.forEach(record => {
                    jsonObject[record.Key] = record.Value;
                  });
                }
                const formattedJson = JSON.stringify(jsonObject, null, 2);
                const jsonLabel = formattedJson
                  .toString()
                  .replaceAll(',', '<br/>')
                  .replaceAll('}', ' ')
                  .replaceAll('{', ' ')
                  .replaceAll('"', ' ');
                const valueLabel = Array.isArray(record.Value) ? (
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: jsonLabel
                    }}
                    sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 600 }}
                  />
                ) : (
                  <Typography sx={{ color: 'neutral.body', display: 'inline-block', fontSize: 14, fontWeight: 600 }}>{record.Value}</Typography>
                );
                return (
                  <ListItem key={record.Key} sx={{ display: 'list-item', p: 0 }}>
                    <Typography sx={{ color: 'neutral.body', fontSize: 14, fontWeight: 400 }}>
                      {record.Key}: {valueLabel}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Stack>
      </Stack>
      <Typography
        sx={{ color: 'neutral.50', fontSize: 12, fontWeight: 400, mt: 1, mb: 2, ml: 2 }}
      >{`${createdAt.date} ${createdAt.time}`}</Typography>
      <Stack alignItems="flex-start" direction="row" justifyItems="space-beween" sx={{ width: '100%' }}>
        <Stack alignItems="center" direction="row" gap={1} sx={{ width: '100%' }}>
          <Avatar src={data?.user.avatar} sx={{ height: 24, mx: { xs: 2, md: 3 }, width: 24 }} />
          <Typography sx={{ color: 'neutral.body', fontSize: { xs: 12, lg: 14 }, fontWeight: 400, textAlign: { xs: 'center', md: 'auto' } }}>
            {data?.user.email}
          </Typography>
        </Stack>
        <Stack alignItems="flex-end" justifyContent="flex-end" gap={1} sx={{ width: '100%', mr: 3 }}>
          <LogModal data={data} open={open} setOpen={setOpen}>
            <Tooltip title="Informações">
              <IconButton
                sx={{ borderRadius: 1, bgcolor: 'neutral.light', color: 'neutral.50', height: 24, width: 24 }}
                onClick={() => setOpen(true)}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </LogModal>
          {recordIsArray && (
            <Button
              color="secondary"
              endIcon={<KeyboardArrowDownIcon sx={{ transform: showMore ? 'rotate(3.142rad)' : 'unset' }} />}
              sx={{ fontSize: { xs: 9, lg: 14 }, height: 24, ml: { xs: 2, md: 3 }, px: { xs: 1, md: 3 } }}
              onClick={() => setShowMore(prevState => !prevState)}
            >
              {showMoreButtonLabel}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export default MobileRow;
