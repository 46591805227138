import React from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';

import { useLang } from './Metronici18n';
import ptBrMessages from './messages/pt-br';

const allMessages = {
  'pt-br': ptBrMessages
};

export const intl = createIntl(
  {
    locale: 'pt-br',
    messages: allMessages['pt-br']
  },
  createIntlCache()
);

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
