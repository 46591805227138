import { useConfirmationDialog } from '@app/components/ConfirmDialog';
import {
  CloseRounded as CloseIcon,
  TaskAlt as TaskAltIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@mui/icons-material';
import { Box, Button, CardContent, IconButton, Modal, OutlinedInput, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import addNotification from '../../../utils/addNotification';
import { integrateTencent } from '../services';

const InputLabels = ({ title, name, value, visible, setIsVisible, onChange }) => (
  <Stack gap={1}>
    <Typography sx={{ color: 'neutral.body', fontSize: 14 }}>
      {title}
      <Typography sx={{ color: 'error.main', display: 'inline-block', ml: 1 }}>*</Typography>
    </Typography>
    <OutlinedInput
      name={name}
      type={visible ? 'text' : 'password'}
      rows={2}
      endAdornment={
        visible ? (
          <VisibilityIcon sx={{ color: 'neutral.25', cursor: 'pointer' }} onClick={() => setIsVisible(false)} />
        ) : (
          <VisibilityOffIcon sx={{ color: 'neutral.25', cursor: 'pointer' }} onClick={() => setIsVisible(true)} />
        )
      }
      value={value}
      onChange={onChange}
      sx={{ height: 37 }}
    />
  </Stack>
);

const TencentButton = ({ disabled, setTencentIntegrationIsActive, setTencentImportIsActive, isLoading }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(false);
  const [keyIsVisible, setKeyIsVisible] = useState(false);
  const [idIsVisible, setIdIsVisible] = useState(false);
  const getConfirmation = useConfirmationDialog();
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      secretId: '',
      secretKey: ''
    },
    onSubmit: async () => {
      try {
        setIsLoadingIntegration(true);
        await integrateTencent(form.values);
        await getConfirmation({
          title: (
            <Stack alignItems="center" direction="row" gap={2}>
              <TaskAltIcon color="success" />
              Playlist criada
            </Stack>
          ),
          message: (
            <Typography>
              A integração entre <b>Spalla Stream</b> e <b>Tencent Cloud - VoD</b> foi bem sucedida. Uma nova pasta será criada na sua biblioteca de
              conteúdos.
            </Typography>
          ),
          cancelButtonProps: { sx: { display: 'none' } },
          confirmButtonProps: { children: 'Fechar' }
        });
        setIsLoadingIntegration(false);
        setOpenModal(false);
        setTencentIntegrationIsActive(prevState => !prevState);
        setTencentImportIsActive(true);
      } catch {
        setIsLoadingIntegration(false);
        addNotification({
          message: 'Erro ao efetuar integração.',
          type: 'danger'
        });
      }
    }
  });
  const inputLabels = [
    {
      title: 'Chave secreta',
      name: 'secretKey',
      value: form.values.secretKey,
      visible: keyIsVisible,
      setIsVisible: setKeyIsVisible,
      onChange: form.handleChange
    },
    {
      title: 'ID secreto',
      name: 'secretId',
      value: form.values.secretId,
      visible: idIsVisible,
      setIsVisible: setIdIsVisible,
      onChange: form.handleChange
    }
  ];
  return (
    <>
      <Button
        disabled={isLoading || disabled}
        size="large"
        sx={{ height: 37, width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
        onClick={() => setOpenModal(true)}
      >
        Integração
      </Button>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onKeyDown={e => {
          e.stopPropagation();
        }}
      >
        <Box
          sx={{
            bgcolor: 'common.white',
            borderRadius: 1,
            boxShadow: 24,
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ m: 4 }}>
            <Typography sx={{ color: 'neutral.main', fontSize: 16 }}>Integração Tencent Cloud</Typography>
            <IconButton onClick={() => setOpenModal(false)} sx={{ p: 0 }}>
              <CloseIcon sx={{ color: 'muted' }} />
            </IconButton>
          </Stack>
          <Stack alignItems="center" direction="row" justifyContent="space-between" gap={3} sx={{ bgcolor: 'neutral.light', px: 4, py: 3 }}>
            <Button color="primary" sx={{ width: '50%' }}>
              VoD
            </Button>
            <Button disabled sx={{ width: '50%', '&:disabled': { color: 'common.white' } }}>
              COS
            </Button>
          </Stack>
          <CardContent sx={{ pb: 0 }}>
            <Stack gap={3}>
              {inputLabels.map(label => (
                <InputLabels
                  key={label.name}
                  title={label.title}
                  name={label.name}
                  value={label.value}
                  visible={label.visible}
                  setIsVisible={label.setIsVisible}
                  onChange={label.onChange}
                />
              ))}
            </Stack>
          </CardContent>
          <Stack gap={2} sx={{ p: 4 }}>
            <LoadingButton
              disabled={form.values.secretId.length === 0 || form.values.secretKey.length === 0}
              loading={isLoadingIntegration}
              onClick={form.handleSubmit}
            >
              Integrar
            </LoadingButton>
            <Button color="secondary" onClick={() => setOpenModal(false)}>
              Cancelar
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default TencentButton;
