import { Grid, Typography } from '@mui/material';
import { UnfoldMoreRounded as UnfoldMoreIcon } from '@mui/icons-material';

import React from 'react';

const RecordsHeader = ({ sortDirection, setDirection }) => {
  const sortByHandler = () => (sortDirection === 'desc' ? setDirection('asc') : setDirection('desc'));
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={1.5} gap={2} sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex' }} onClick={sortByHandler}>
        <Typography sx={{ color: 'muted', fontSize: 12, fontWeight: 600, ml: 4 }}>DATA</Typography>
        <UnfoldMoreIcon sx={{ color: 'muted', width: 15 }} />
      </Grid>
      <Grid item xs={0.5} sx={{ alignItems: 'center', display: 'flex', width: 60 }} />
      <Grid item xs={6.5} sx={{ alignItems: 'center', display: 'flex' }}>
        <Typography sx={{ color: 'muted', fontSize: 12, fontWeight: 600 }}>AÇÃO</Typography>
      </Grid>
      <Grid item xs={1} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
        <Typography sx={{ color: 'muted', fontSize: 12, fontWeight: 600 }}>RESPONSÁVEL</Typography>
      </Grid>
      <Grid item xs={2.5} />
    </Grid>
  );
};

export default RecordsHeader;
