import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

const RecordsRowSkeletons = ({ isMobile }) => (
  <>
    {isMobile ? (
      <Stack direction="row" sx={{ pl: 3, width: '100%' }}>
        <Skeleton variant="circular" sx={{ height: 24, width: 24 }} />
        <Stack sx={{ width: '100%', pl: 2 }}>
          <Skeleton variant="text" sx={{ height: 24, width: 170 }} />
          <Skeleton variant="text" sx={{ height: 24, width: 170 }} />
          <Skeleton variant="text" sx={{ height: 24, width: 170 }} />
          <Stack alignItems="flex-start" ustifyItems="space-beween">
            <Stack alignItems="center" direction="row" gap={1} sx={{ mr: 3 }}>
              <Skeleton variant="circular" sx={{ height: 24, width: 24 }} />
              <Skeleton variant="text" sx={{ height: 24, width: 100, mr: 3 }} />
            </Stack>
            <Stack alignItems="flex-end" justifyContent="flex-end" gap={2} sx={{ width: '90%', mr: 3 }}>
              <Skeleton variant="text" sx={{ height: 24, width: 24 }} />
              <Skeleton variant="text" sx={{ height: 24, width: 70 }} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    ) : (
      <Grid container>
        <Grid item xs={1.5} sx={{ alignItems: 'center', display: 'flex', pl: 4 }}>
          <Skeleton variant="text" sx={{ height: 21, width: 114 }} />
        </Grid>
        <Grid item xs={0.5} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: 60 }}>
          <Skeleton variant="circular" sx={{ height: 24, width: 24 }} />
        </Grid>
        <Grid item xs={5.5} sx={{ alignItems: 'center', display: 'flex', pr: 2 }}>
          <Skeleton variant="text" sx={{ height: 21, width: '100%' }} />
        </Grid>
        <Grid item xs={1.5} sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', pr: 4 }}>
          <Skeleton variant="text" sx={{ height: 21, width: 160 }} />
        </Grid>
        <Grid container item xs={3} gap={3} sx={{ alignItems: 'center', display: 'flex', pr: 1 }}>
          <Skeleton variant="circular" sx={{ height: 24, width: 24 }} />
          <Skeleton variant="circular" sx={{ height: 24, width: 24 }} />
          <Skeleton variant="text" sx={{ height: 24, width: 170 }} />
        </Grid>
      </Grid>
    )}
  </>
);

export default RecordsRowSkeletons;
