import { Box } from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import { Context } from '../../Context';

const Chat = ({ height }) => {
  const { arenaUser } = useContext(Context);
  const startArenaSSO = useCallback(() => {
    const { name, email } = arenaUser;
    window.arenaSSO.signOut();
    window.arenaSSO.authenticate({
      id: `${email}-${name}`,
      email: email,
      name
    });
  }, [arenaUser]);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://go.arena.im/public/js/arenachatlib.js?p=taghos&e=taghos-global';
    script.async = true;

    setTimeout(() => {
      document.body.appendChild(script);
      if (window.arenaSSO) {
        startArenaSSO();
      } else {
        document.addEventListener('arena-im-api-ready', startArenaSSO, false);
      }
    }, 400);

    return () => {
      document.body.removeChild(script);
    };
  }, [startArenaSSO]);
  return (
    <Box sx={{ height: { xs: 480, lg: height }, width: { xs: '100%', lg: '32%' } }}>
      <div class="arena-chat" data-publisher="taghos" data-chatroom="taghos-global" data-position="in-page"></div>
    </Box>
  );
};

export default Chat;
