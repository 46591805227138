import { KeyboardArrowDownRounded as ArrowDownIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import DatePicker from './DatePicker';
import { defaultRanges } from './DatePicker/defaults';
import { Popover, Stack, Typography } from '@mui/material';

const DateRangePickerComponent = ({ dateRange, setDateRange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseModal = () => setAnchorEl(null);
  const handleOpenModal = event => setAnchorEl(event.currentTarget);
  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <DatePicker open definedRanges={defaultRanges} initialDateRange={dateRange} onChange={range => setDateRange(range)} />
      </Popover>
      <Typography sx={{ color: 'neutral.body', fontSize: 12, my: 2 }}>Selecionar período</Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        gap={3}
        sx={{
          bgcolor: 'neutral.light',
          borderRadius: 1,
          color: 'neutral.50',
          cursor: 'pointer',
          height: 37,
          minWidth: 166,
          px: 3,
          py: 2
        }}
        onClick={handleOpenModal}
      >
        <Typography variant="body3" sx={{ color: 'neutral.main' }}>
          Selecionar
        </Typography>
        <ArrowDownIcon />
      </Stack>
    </>
  );
};

export default DateRangePickerComponent;
