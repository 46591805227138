import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Grow,
  MenuItem,
  Paper,
  Popper,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { FilterAlt as FilterAltIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { AuthorizedSection } from '@tshio/react-router-permissions';
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { getClients } from '@app/modules/Streaming/services/live';
import DateRangePickerComponent from './DateRangePicker';
import { startOfYear } from 'date-fns';
import { shallowEqual, useSelector } from 'react-redux';
import { getTeams } from '../../../../Streaming/services/live';

const FilterButton = ({
  clientId,
  onChangeClientId,
  buttonProps,
  dateRange,
  setDateRange,
  clearAccountId,
  teamId,
  onChangeTeamId,
  showTeamSelector = true
}) => {
  const [teamSelectOpen, setTeamSelectOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [clientSelectOpen, setClientSelectOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { isLoading, data, isError } = useQuery('getClients', getClients);
  const anchorRef = useRef(null);
  const { permissions } = useSelector(state => state.auth, shallowEqual);
  const clientIdIsDefault = clientId === '';
  const newClientId = clientIdIsDefault ? '' : clientId;
  const hasSpallaDevPermission = permissions.some(permission => permission === 'spalla-dev');
  const firstOptionSelected = clientIdIsDefault && hasSpallaDevPermission;
  useQuery([clientId], () => getTeams(newClientId), {
    enabled: !firstOptionSelected && showTeamSelector,
    onSuccess: teamData => setTeams(teamData.items)
  });
  if (isLoading || isError) {
    return <Skeleton sx={{ height: { xs: 37, lg: 50 }, minWidth: 37, width: { xs: 37, lg: 50 } }} />;
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = () => {
    const hasDropdownOpened = clientSelectOpen || teamSelectOpen;
    if (hasDropdownOpened) return;
    setOpen(false);
  };
  const clients = data?.data;
  const handleClientChange = (_, newValue) => {
    const selectedClientId = newValue ? newValue.conta_id : '';
    changeClientId({ target: { value: selectedClientId } });
  };
  const getClientByAccountId = accountId => {
    return clients.find(client => client.conta_id === accountId) || null;
  };
  const changeClientId = event => {
    if (showTeamSelector) {
      onChangeTeamId('');
    }
    onChangeClientId(event.target.value);
  };
  const changeTeamId = event => onChangeTeamId(event.target.value);
  const hasNoTeams = teams.length === 0;
  const isDefaultClient = clientId === '';
  const mustNotShowTeams =
    (hasSpallaDevPermission && isDefaultClient) || (hasNoTeams && isDefaultClient) || hasNoTeams || clientId === '' || !showTeamSelector;
  const clearFilters = () => {
    setDateRange({
      startDate: startOfYear(new Date()),
      endDate: new Date()
    });
    clearAccountId();
    onChangeTeamId('');
    onChangeClientId('');
  };
  return (
    <>
      <Tooltip title="Filtros">
        <Button
          ref={anchorRef}
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleToggle}
          {...buttonProps}
          sx={{ height: { xs: 37, lg: 44 }, mr: 3, minWidth: 37, p: 2, width: { xs: 37, lg: 44 }, ...buttonProps?.sx }}
        >
          <FilterAltIcon sx={{ color: 'primary.main' }} />
        </Button>
      </Tooltip>
      <Popper anchorEl={anchorRef.current} open={open} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card sx={{ borderRadius: 1, filter: 'drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.15))', width: 300 }}>
                  <CardContent>
                    <AuthorizedSection requires="spalla-dev">
                      {({ isAuthorized }) =>
                        isAuthorized && (
                          <>
                            <Typography sx={{ color: 'neutral.body', fontSize: 12, mb: 2 }}>Selecionar cliente</Typography>
                            <Autocomplete
                              getOptionLabel={client => client.nome_cliente}
                              noOptionsText="Nenhum cliente encontrado"
                              onClose={() => setClientSelectOpen(false)}
                              onChange={handleClientChange}
                              onOpen={() => setClientSelectOpen(true)}
                              options={clients}
                              popupIcon={<KeyboardArrowDownIcon sx={{ cursor: 'pointer', mr: 2 }} />}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Selecionar"
                                  sx={{
                                    bgcolor: 'neutral.light',
                                    color: 'neutral.body',
                                    height: 37,
                                    '.MuiAutocomplete-inputRoot': {
                                      borderWidth: 0
                                    }
                                  }}
                                />
                              )}
                              sx={{
                                bgcolor: 'neutral.light',
                                fieldset: { display: 'none' },
                                height: 37,
                                width: '100%'
                              }}
                              value={getClientByAccountId(clientId)}
                            />
                          </>
                        )
                      }
                    </AuthorizedSection>
                    <DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />
                    {!mustNotShowTeams && (
                      <AuthorizedSection requires="gerenciarTimes">
                        {({ isAuthorized }) =>
                          isAuthorized && (
                            <>
                              <Typography sx={{ color: 'neutral.body', fontSize: 12, mb: 2, mt: 3 }}>Time</Typography>
                              <Select
                                placeholder="Selecionar"
                                disabled={hasNoTeams}
                                value={teamId}
                                onOpen={() => setTeamSelectOpen(true)}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                  TransitionProps: {
                                    onExited: () => setTeamSelectOpen(false)
                                  }
                                }}
                                sx={{ border: 'none', bgcolor: 'neutral.light', height: 40, fieldset: { display: 'none' }, width: '100%' }}
                                onChange={changeTeamId}
                              >
                                <MenuItem value="">Selecionar</MenuItem>
                                {teams.map(team => (
                                  <MenuItem key={team.accountId} value={team.accountId}>
                                    {team.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )
                        }
                      </AuthorizedSection>
                    )}
                    <Button onClick={clearFilters} sx={{ mt: 3, width: '100%' }}>
                      Limpar filtros
                    </Button>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default FilterButton;
