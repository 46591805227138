import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import SkeletonItem from '../../components/SkeletonItem';
import Speakers from './Speakers';

const Footer = ({ isLoading, infos }) => (
  <Box
    sx={{
      backgroundImage: { xs: infos?.background ? `url(${infos.background})` : 'url(/media/cogna/default-background.png)', lg: 'none' },
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      mt: { xs: 0, lg: 3 },
      pb: { xs: 6, lg: '115px' },
      pt: { xs: 3, lg: 0 },
      px: { xs: 3, lg: 0 }
    }}
  >
    <Speakers isLoading={isLoading} speakers={infos?.speakers || []} />
    <Stack alignItems="center" direction="row" justifyContent="center" gap={3} sx={{ mt: 3, width: '100%' }}>
      <Avatar
        src={infos?.schedule_image.includes('placeholder.png') || isLoading ? '/media/cogna/default-logo.png' : infos.schedule_image}
        sx={{ height: 120, width: 120 }}
      />
      <Stack gap={2}>
        <Typography sx={{ color: 'common.white', fontSize: 20, fontWeight: 600 }}>
          {isLoading ? <SkeletonItem height={36} width={{ xs: 160, lg: 318 }} /> : infos.title}
        </Typography>
        <Typography sx={{ color: 'neutral.light', fontSize: 12, fontWeight: 400 }}>
          {isLoading ? <SkeletonItem height={36} width={{ xs: 160, lg: 318 }} /> : infos.schedule_date}
        </Typography>
        <Typography sx={{ color: 'neutral.light', fontSize: 16, fontWeight: 400, maxWidth: 336 }}>
          {isLoading ? <SkeletonItem height={36} width={{ xs: 160, lg: 318 }} /> : infos.description}
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default Footer;
