import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const DefaultAuthModal = forwardRef(({ buttonConfirmTitle, color = 'primary', description, loading, title, onConfirm }, ref) => {
  const onConfirmLoginModal = async () => {
    await onConfirm();
  };
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal
    }),
    []
  );
  return (
    <Modal open={open} onClose={closeModal}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          bottom: { xs: 0, sm: 'auto' },
          boxShadow: 24,
          left: { xs: 0, sm: '50%' },
          p: 4,
          position: 'absolute',
          top: { xs: 'auto', sm: '50%' },
          transform: { xs: 'none', sm: 'translate(-50%, -50%)' },
          width: { xs: '100%', sm: 500 }
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ color: 'neutral.dark', fontSize: 16 }}>{title}</Typography>
          <CloseRoundedIcon sx={{ color: 'muted', cursor: 'pointer' }} onClick={closeModal} />
        </Stack>
        <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
        <Typography sx={{ color: 'neutral.body', fontSize: 14, mb: 3 }}>{description}</Typography>
        <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
        <Stack gap={2} sx={{ mt: 3 }}>
          <LoadingButton color={color} loading={loading} onClick={onConfirmLoginModal}>
            {buttonConfirmTitle}
          </LoadingButton>
          <Button sx={{ bgcolor: 'neutral.light', color: 'neutral.50', '&:hover': { bgcolor: 'neutral.light' } }} onClick={closeModal}>
            Cancelar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
});

export default DefaultAuthModal;
