import { Stack } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NewPassword from '../pages/NewPassword';
import RecoverPassword from '../pages/RecoverPassword';

const RecoverPasswordTemplate = () => (
  <>
    <Stack alignItems="center" justifyContent="center" sx={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', height: '65px' }}>
      <img src="/media/logos/logo-1.svg" alt="Logo do Spalla" style={{ width: 112 }} />
    </Stack>
    <Switch>
      <Route path="/auth/recover-password/new" exact component={NewPassword} />
      <Route path="" component={RecoverPassword} />
    </Switch>
  </>
);

export default RecoverPasswordTemplate;
