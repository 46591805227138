import { Button } from '@mui/material';
import React, { forwardRef, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DefaultAuthModal from './DefaultAuthModal';

const DefaultLoginButton = forwardRef(({ query, disabled, getToken, modalTitle, modalDescription }, ref) => {
  const { data, isLoading, refetch } = query;
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;
  const accountId = user.contaId;
  const loginModalRef = useRef(null);
  const onSignInWithGoogleMeetHandler = () => {
    loginModalRef.current.openModal();
  };
  const onConfirmLoginModal = async () => {
    const isLoggedIn = await getToken(accountId, data?.length || 0);
    if (isLoggedIn) {
      loginModalRef.current.closeModal();
      await refetch();
    }
  };
  return (
    <>
      <Button
        color="primary"
        disabled={disabled || isLoading}
        size="large"
        sx={{ height: 37, width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
        onClick={onSignInWithGoogleMeetHandler}
      >
        Integração
      </Button>
      <DefaultAuthModal
        ref={loginModalRef}
        buttonConfirmTitle="Adicionar integração"
        title={modalTitle}
        description={modalDescription}
        onConfirm={onConfirmLoginModal}
      />
    </>
  );
});

export default DefaultLoginButton;
