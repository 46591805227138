import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Divider, IconButton, Modal, Stack, Typography } from '@mui/material';
import {
  CloseRounded as CloseIcon,
  Check as CheckIcon,
  ExpandMore as ExpandMoreIcon,
  FileUpload as FileUploadIcon,
  OndemandVideo as VideoIcon,
  SignalCellularAltRounded as SignalIcon
} from '@mui/icons-material';
import Info from '../Info';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

const ProgressModal = ({ videosProgress, closeModalHandler, modalIsOpen }) => {
  const detailsLabel = [
    {
      id: 1,
      icon: <VideoIcon sx={{ color: 'muted' }} />,
      title: 'Enviando vídeo',
      type: 'upload',
      progressIcon: <FileUploadIcon size={12} sx={{ color: 'primary.main' }} />
    },
    {
      id: 2,
      icon: <SignalIcon sx={{ color: 'muted' }} />,
      title: 'Transcodificando vídeo',
      type: 'transcode',
      progressIcon: <CircularProgress size={12} />
    }
  ];
  return (
    <Modal open={modalIsOpen} onClose={closeModalHandler}>
      <Box
        sx={{
          borderRadius: 1,
          boxShadow: 24,
          bgcolor: 'background.paper',
          height: 406,
          left: '50%',
          outline: 'none',
          position: 'absolute',
          py: 4,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 498
        }}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ px: 4 }}>
          <Stack>
            <Typography
              sx={{
                color: 'secondary.main',
                fontSize: 14,
                fontWeight: 600
              }}
            >
              Processando conteúdos <Info tooltip="Monitore o processo de até 100 conteúdos." sx={{ display: 'inline-block' }} />
            </Typography>
            <Typography
              sx={{
                color: 'muted',
                fontSize: 12,
                mt: '-12px'
              }}
            >
              Total restantes: {videosProgress?.length} conteúdos
            </Typography>
          </Stack>
          <IconButton onClick={closeModalHandler}>
            <CloseIcon sx={{ color: 'muted', cursor: 'pointer' }} />
          </IconButton>
        </Stack>
        <Divider sx={{ bgcolor: 'grey.200', mb: 3, mt: 2 }} />
        <PerfectScrollbar options={perfectScrollbarOptions} className="scroll" style={{ maxHeight: 303, marginRight: '16px', paddingRight: '8px' }}>
          {videosProgress.map(video => {
            const processCompleted = video.progress === 100;
            const progressLabel = processCompleted ? <CheckIcon color="success" /> : `${video.progress}%`;
            const titleLabel = video.title || video.name;
            return (
              <>
                <Accordion
                  disableGutters
                  sx={{
                    '::before': { height: 0 }
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMoreIcon />
                      </IconButton>
                    }
                    sx={{ p: 0 }}
                  >
                    <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ pl: 4, width: '100%' }}>
                      <Typography
                        sx={{ color: 'neutral.body', fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', width: 300, whiteSpace: 'nowrap' }}
                      >
                        {titleLabel}
                      </Typography>
                      <Stack alignItems="center" direction="row" gap={1}>
                        <Typography sx={{ color: 'primary.main', fontSize: 12 }}>{progressLabel}</Typography>
                        {!processCompleted && <CircularProgress size={12} />}
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pl: 4 }}>
                    <Stack gap={2}>
                      {detailsLabel.map(label => (
                        <Stack
                          key={label.id}
                          alignItems="center"
                          direction="row"
                          justifyContent="space-between"
                          sx={{ display: video.type === label.type ? 'flex' : 'none' }}
                        >
                          <Typography sx={{ color: 'muted', fontSize: 12 }}>
                            {label.icon}
                            <Typography sx={{ color: 'neutral.50', display: 'inline-block', ml: 2 }}>{label.title}</Typography>
                          </Typography>
                          <Stack alignItems="center" direction="row" gap={1}>
                            <Typography sx={{ color: 'primary.main', fontSize: 12 }}>{progressLabel}</Typography>
                            {!processCompleted && label.progressIcon}
                          </Stack>
                        </Stack>
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Divider sx={{ bgcolor: 'neutral.25', my: 2 }} />
              </>
            );
          })}
        </PerfectScrollbar>
      </Box>
    </Modal>
  );
};

export default ProgressModal;
