import { getDate, isSameMonth, isToday, isWithinInterval } from 'date-fns';
import { chunks, inDateRange, isEndOfRange, isRangeSameDay, isStartOfRange, getDaysInMonth } from '../utils';
import Day from './Day';
import Header from './Header';
import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import formatDate from '../utils/formatDate';
import { WEEK_DAYS } from '../../../../../../../utils/calendarInfo';

const Month = ({ dateRange, helpers, handlers, marker, maxDate, minDate, navState, value: date, setValue: setDate }) => {
  const [back, forward] = navState;
  const monthDays = chunks(getDaysInMonth(date), 7);
  const generateDay = day => {
    const isStart = isStartOfRange(dateRange, day);
    const isEnd = isEndOfRange(dateRange, day);
    const isRangeOneDay = isRangeSameDay(dateRange);
    const highlighted = inDateRange(dateRange, day) || helpers.inHoverRange(day);
    const dayIsDisabled =
      !isSameMonth(date, day) ||
      !isWithinInterval(day, {
        start: minDate,
        end: maxDate
      });
    const startOfRange = isStart && !isRangeOneDay;
    const endOfRange = isEnd && !isRangeOneDay;
    return (
      <Day
        key={formatDate(day, 'mm-dd-yyyy')}
        filled={isStart || isEnd}
        isToday={isToday(day)}
        highlighted={highlighted && !isRangeOneDay}
        disabled={dayIsDisabled}
        startOfRange={startOfRange}
        endOfRange={endOfRange}
        onClick={() => handlers.onDayClick(day)}
        onHover={() => handlers.onDayHover(day)}
        value={getDate(day)}
      />
    );
  };
  return (
    <Paper square elevation={0} sx={{ width: 290 }}>
      <Grid container>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() => handlers.onMonthNavigate(marker, -1)}
          onClickNext={() => handlers.onMonthNavigate(marker, 1)}
        />
        <Grid item container direction="row" justifyContent="space-between" sx={{ marginTop: 2, paddingLeft: 6, paddingRight: 6 }}>
          {WEEK_DAYS.map(day => (
            <Typography color="textSecondary" key={day} variant="caption">
              {day}
            </Typography>
          ))}
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          sx={{
            marginBottom: 3,
            marginTop: 3,
            paddingLeft: 3,
            paddingRight: 3
          }}
        >
          {monthDays.map((week, idx) => (
            <Grid key={idx} container direction="row" justifyContent="center">
              {week.map(day => generateDay(day))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Month;
