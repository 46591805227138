import { FormHelperText, OutlinedInput, Stack, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchLogin } from '../../../_redux/authRedux';
import * as auth from '../../../_redux/authRedux';
import validationSchema from './providers/validationSchema';

const formHelperTextProps = { color: 'error' };

const Login = () => {
  const [submiting, setSubmiting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const enableSubmiting = () => setSubmiting(true);
  const disableSubmiting = () => setSubmiting(false);
  const loginSchema = validationSchema();
  const form = useFormik({
    initialValues: {
      login: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      enableSubmiting();
      dispatch(
        fetchLogin(values.login, values.password, loading => {
          disableSubmiting();
          form.setErrors({ submit: 'E-mail ou senha incorretos' });
        })
      );
    }
  });
  const toggleShowPassword = () => setShowPassword(prevState => !prevState);
  const hasErrors = fieldName => form.touched[fieldName] && form.errors[fieldName];
  const Icon = showPassword ? Visibility : VisibilityOff;
  const renderIcon = <Icon sx={{ cursor: 'pointer' }} onClick={toggleShowPassword} />;
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent={{ xs: 'flex-start', md: 'center' }}
        gap={{ xs: 4, md: '120px' }}
        sx={{ height: { xs: 'auto', md: 324 }, width: { xs: 327, md: 1008 } }}
      >
        <Stack alignItems={{ xs: 'center', md: 'flex-start' }} sx={{ width: '100%' }}>
          <img src="/media/logos/logo-1.svg" alt="Logo do Spalla" style={{ width: 176 }} />
          <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600, mt: { xs: 4, md: 5 }, textAlign: { xs: 'center', md: 'initial' } }}>
            Todo o seu Streaming, em um só lugar.
          </Typography>
          <Typography sx={{ color: 'neutral.50', fontSize: 16, mt: { xs: 4, md: 2 }, textAlign: { xs: 'center', md: 'initial' } }}>
            Seja Live Streaming ou VoD, crie transmissões em alguns cliques com o Spalla.
          </Typography>
        </Stack>
        <Stack
          sx={{
            borderColor: { xs: 'none', md: 'neutral.25' },
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: { xs: 0, md: '1px' },
            minHeight: { xs: '100%', md: 324 },
            px: { xs: 0, md: 4 },
            py: { xs: 0, md: 5 },
            width: '100%'
          }}
        >
          <form onSubmit={form.handleSubmit}>
            <Typography
              data-testid="titleLoginScreen"
              sx={{ color: 'neutral.body', display: { xs: 'none', md: 'initial' }, fontSize: 20, fontWeight: 600 }}
            >
              Entrar com conta Spalla
            </Typography>
            <OutlinedInput
              data-testid="loginEmailInput"
              name="login"
              value={form.values.login}
              placeholder="E-mail"
              sx={{
                height: 37,
                mt: { xs: 0, md: 4 },
                width: '100%'
              }}
              onChange={form.handleChange}
            />
            {hasErrors('login') && (
              <FormHelperText data-testid="loginEmailError" {...formHelperTextProps}>
                {form.errors.login}
              </FormHelperText>
            )}
            <OutlinedInput
              data-testid="loginPasswordInput"
              name="password"
              endAdornment={renderIcon}
              placeholder="Senha"
              type={showPassword ? 'text' : 'password'}
              value={form.values.password}
              sx={{ height: 37, mt: { xs: 3, md: 3 }, width: '100%' }}
              onChange={form.handleChange}
            />
            {hasErrors('password') && (
              <FormHelperText data-testid="loginPasswordError" {...formHelperTextProps}>
                {form.errors.password}
              </FormHelperText>
            )}
            {form.errors.submit && (
              <FormHelperText data-testid="loginSubmitPasswordError" {...formHelperTextProps}>
                {form.errors.submit}
              </FormHelperText>
            )}
            <Link to="/auth/recover-password">
              <Typography data-testid="forgotPasswordButton" sx={{ color: 'primary.main', fontSize: 16, mt: { xs: 2, md: 3 }, width: 162 }}>
                Esqueceu a senha?
              </Typography>
            </Link>
            <LoadingButton data-testid="loginButton" loading={submiting} type="submit" size="large" sx={{ fontSize: 16, mt: 5, width: '100%' }}>
              Entrar
            </LoadingButton>
          </form>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default injectIntl(connect(null, auth.actions)(Login));
