import Axios from 'axios';

export const zoomActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/importacao-ativa`, payload);
export const zoomMultiActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/importacao-ativa-multi`, payload);
export const tencentActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/importacao-ativa`, payload);
export const googleMeetActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/google-meet/importacao-ativa`, payload);
export const youtubeActivate = payload => Axios.put(`${process.env.REACT_APP_NOVA_API_URL}/bff/spalla-youtube/importacao-ativa`, payload);
export const integrateTencent = payload => Axios.post(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/migra-gravacoes`, payload);
export const cancelTencentIntegration = () => Axios.delete(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/migra-gravacoes`);
export const getTencentStatus = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/tencent/vod/importacao-ativa`).then(data => data.data);
export const getGoogleMeetStatus = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/google-meet/importacao-ativa`).then(data => data.data);
export const getYoutubeStatus = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/spalla-youtube/importacao-ativa`).then(data => data.data);
export const cancelGoogleMeetIntegration = () => Axios.post(`${process.env.REACT_APP_NOVA_API_URL}/bff/google-meet/deauthorize`);
export const cancelYoutubeIntegration = () => Axios.post(`${process.env.REACT_APP_NOVA_API_URL}/bff/spalla-youtube/deauthorize`);
export const getStatusToken = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/status-token`).then(data => data.data);
export const getMultiStatusToken = () => Axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/zoom/status-token-multi`).then(data => data.data);
// Extrai a URL de autorização do ambiente
const getAuthorizationUrl = (accountId, isMultiAccounts, endpoint) => {
  const multiPath = isMultiAccounts ? '-multi' : '';
  const url = `${process.env.REACT_APP_NOVA_API_URL}${endpoint}${multiPath}?account_id=${accountId}`;
  return url;
};
// Abre uma janela pop-up para autenticação
export const openAuthWindow = url => {
  const width = 600;
  const height = 800;
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  const windowFeatures = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,toolbar=no`;
  return window.open(url, '_blank', windowFeatures);
};
// Função genérica para verificar periodicamente o token de autenticação
const checkAuthToken = async (authWindow, checkStatusFn, isLoggedInFn, intervalTime = 2000) => {
  const response = await new Promise(resolve => {
    const interval = setInterval(async () => {
      try {
        const response = await checkStatusFn();
        const isLoggedIn = isLoggedInFn(response);
        if (isLoggedIn) {
          // Se retornar true fecha o popup
          authWindow.close();
          clearInterval(interval);
          resolve(true);
        }

        if (authWindow.closed) {
          resolve(false);
          clearInterval(interval);
        }
      } catch {}
    }, intervalTime);
  });

  return response;
};
// Função principal para obter um token de autenticação do zoom
export const getZoomAuthToken = async (accountId, isMultiAccounts, accountsLength) => {
  const authUrl = getAuthorizationUrl(accountId, isMultiAccounts, '/bff/zoom/oauth'); // Obter a URL de autorização
  const authWindow = openAuthWindow(authUrl); // Abrir janela de autenticação

  // Verificar periodicamente se está logado
  return checkAuthToken(authWindow, isMultiAccounts ? getMultiStatusToken : getStatusToken, response =>
    isMultiAccounts ? !response.deauthorized : response?.length > accountsLength
  );
};
// Função principal para obter um token de autenticação Google Meet
export const getGoogleMeetAuthToken = async accountId => {
  const authUrl = getAuthorizationUrl(accountId, false, '/bff/google-meet/oauth'); // Obter a URL de autorização
  const authWindow = openAuthWindow(authUrl); // Abrir janela de autenticação

  // Verificar periodicamente se está logado
  return checkAuthToken(authWindow, getGoogleMeetStatus, response => !!response && !response.deauthorized);
};
// Função principal para obter um token de autenticação Youtube
export const getYoutubeAuthToken = async accountId => {
  const authUrl = getAuthorizationUrl(accountId, false, '/bff/spalla-youtube/oauth'); // Obter a URL de autorização
  const authWindow = openAuthWindow(authUrl); // Abrir janela de autenticação

  // Verificar periodicamente se está logado
  return checkAuthToken(authWindow, getYoutubeStatus, response => !!response && !response.deauthorized);
};
