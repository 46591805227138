import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import ptBR from './messages/pt-br.json';
import en from './messages/en.json';

const messageList = {
  'pt-BR': ptBR,
  en
};

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const defaultLanguage = 'pt-BR';

export const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState(() => {
    const previouslyDefinedLanguage = localStorage.getItem('spalla_language');
    return previouslyDefinedLanguage || defaultLanguage;
  });
  const [messages, setMessages] = useState(messageList[locale]);

  const getLanguageToCookie = language => {
    switch (language) {
      case 'pt-BR':
        return 'pt_BR';
      default:
        return language;
    }
  };

  const setCookie = (name, value, exdays) => {
    try {
      const date = new Date();
      date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = 'expires=' + date.toUTCString();
      document.cookie = name + '=' + value + ';' + expires + ';path=/;domain=.spalla.io;secure;samesite=lax';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem('spalla_language', locale);
    setCookie('spalla_language', getLanguageToCookie(locale), 3650);
    setMessages(messageList[locale]);
  }, [locale]);

  return (
    <LanguageContext.Provider value={{ locale, messages, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
